/** @format */

import React, { useEffect, useState } from 'react';
import '../../Assets/Css/playlist.css';
import '../../Assets/Css/headingstrip.css';
import PlaylistCard from './PlaylistCard';
import { getCategoriesList, getPlaylists } from '../Api/Api';
import { useTranslation } from 'react-i18next';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import useLang from '../hooks/useLang';
import Dropdown from 'react-bootstrap/Dropdown';
import { Notification } from '../Home/Notification';

function Playlist() {
	const [playlist, setPlaylist] = useState([]);
	const [fitleredPlaylist, setFitleredPlaylist] = useState([]);
	const { currLang, currLangBackend } = useLang();
	const [categoriesList, setCategoriesList] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState('');
	const fetchPlaylists = async () => {
		try {
			const res = await getPlaylists(currLang);
			setPlaylist(res.data.data);
			if (selectedCategory) {
				const playlistFilter = res.data.data?.filter(
					(item) => item.playlist.categoryId === selectedCategory._id
				);
				setPlaylist(playlistFilter);
			}
			setFitleredPlaylist(res.data.data);
		} catch (e) {
			console.log(e);
		}
	};
	const fetchCategoriesList = async () => {
		try {
			const res = await getCategoriesList();
			setCategoriesList(res.data.category);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		fetchPlaylists();
		fetchCategoriesList();
	}, [currLang]);

	const { t } = useTranslation(['main']);
	const handleCategory = (item) => {
		setSelectedCategory(item);
	};
	useEffect(() => {
		if (selectedCategory) {
			const playlistFilter = fitleredPlaylist?.filter(
				(item) => item.playlist.categoryId === selectedCategory._id
			);
			setPlaylist(playlistFilter);
		}
	}, [selectedCategory]);
	return (
		<>
			<section className='playlist-container'>
				<Notification className='notification' />
				<main className='playlist-wrapper '>
					<div className='col-12'>
						<div className='row'>
							<div className='col-3 d-none d-sm-block'>
								<div className='categoryList overflow-hidden mb-2 pb-5'>
									<h5 className='p-3 pb-0'> {t('categories')} </h5>
									<hr />
									<ul className='px-2 scroller pb-5'>
										{categoriesList.map((item) => {
											return (
												<li
													key={item._id}
													onClick={() => handleCategory(item)}
													className={` ${
														selectedCategory._id === item._id
															? 'active-category'
															: ''
													}  `}
												>
													{item[currLangBackend]}
												</li>
											);
										})}
									</ul>
								</div>
							</div>
							<div className='col-9 scroller mobile-screen-playlist'>
								<div className='mb-5'>
									<Dropdown className='my-2 category-resp-dropdown'>
										<Dropdown.Toggle
											variant='white'
											className='w-100 border border-dark'
											id='dropdown-basic'
										>
											{t('categories')}
										</Dropdown.Toggle>

										<Dropdown.Menu>
											{categoriesList.map((item, i) => {
												return (
													<li
														key={item._id}
														onClick={() => handleCategory(item)}
														className={`p-2  ${
															selectedCategory._id === item._id
																? 'active-category'
																: ''
														}  `}
													>
														{item[currLangBackend]}
													</li>
												);
											})}
										</Dropdown.Menu>
									</Dropdown>
									<div className='w-100 d-flex justify-content-between headingStrip-wrapper py-2 pe-5'>
										<h4>
											<SubscriptionsIcon
												style={{ fontSize: '1.7rem' }}
												className='me-2 playlist-resp-icon'
											/>{' '}
											{t('playlist')}{' '}
										</h4>
									</div>
									<div className='d-flex flex-wrap'>
										{playlist.map((item) => {
											return <PlaylistCard key={item._id} item={item} />;
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</section>
		</>
	);
}

export default Playlist;
