/** @format */

import { ArrowBack } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import '../../../Assets/Css/playlist.css';
import { getPlaylist, getPlaylistByTitle } from '../../Api/Api';
import useLang from '../../hooks/useLang';
import PlaylistVideoCard from './PlaylistVideoCard';

function SinglePlaylist() {
	const [videoList, setVideoList] = useState([]);
	const [page, setPage] = useState(0);
	const { t } = useTranslation(['main']);
	const { currLang, currLangBackend } = useLang();
	const [totalCount, setTotalCount] = useState(0);
	const param = useParams();
	const urltitle = param?.id;
	const [currId, setCurrId] = useState('');
	const navigate = useNavigate();
	const [playlistName, setplaylistName] = useState();

	const fetchplaylist = async () => {
		try {
			const res = await getPlaylistByTitle(urltitle);
			setCurrId(res.data?.result?._id);
		} catch (e) {
			console.log(e);
		}
	};

	const fetchVideos = async (page) => {
		try {
			const res = await getPlaylist(currId, page);
			setVideoList(res.data.data);
			console.log(res);
			setplaylistName(res?.data?.data[0]?.playlist?.name);
			setTotalCount(res.data.pageLimit);
		} catch (e) {
			console.log(e);
		}
	};

	const handlePageChange = (clickedPage) => {
		fetchVideos(clickedPage - 1);
		setPage(clickedPage - 1);
	};

	useEffect(() => {
		fetchplaylist();
	}, []);

	useEffect(() => {
		if (currId !== '') {
			fetchVideos(0);
		}
	}, [currId]);
	return (
		<>
			<section className='playlist-container ps-3'>
				<main className='playlist-wrapper scroller'>
					<div className='mb-5'>
						<div className='w-100 d-flex align-items-center justify-content-between headingStrip-wrapper py-2 pe-1 pe-lg-5'>
							<h4 className='mb-0'>
								{' '}
								<IconButton onClick={() => navigate('/')}>
									<ArrowBack />
								</IconButton>
								{t('playlist')} : {videoList[0]?.playlist[currLangBackend]}
							</h4>
							<div className='d-flex justify-content-center align-items-center'>
								<Pagination
									current={page + 1}
									total={totalCount}
									showSizeChanger={false}
									defaultPageSize={10}
									onChange={handlePageChange}
								/>
							</div>
						</div>

						<div className='d-flex flex-wrap mt-3'>
							{videoList.map((item, index) => {
								return (
									<PlaylistVideoCard
										index={index}
										key={index}
										id={item._id}
										playlistUrl={param?.id}
										urltitle={item.urltitle}
										duration={item.duration}
										date={item.date}
										uploadby={item.uploadedBy || 'Admin'}
										currId={currId}
										url={item[currLangBackend]?.url}
										thumbnail={
											item[currLangBackend]?.thumbnail ||
											item.englishInfo?.thumbnail
										}
										title={
											item[currLangBackend]?.title || item.englishInfo?.title
										}
										description={
											item[currLangBackend]?.description ||
											item.englishInfo?.description
										}
									/>
								);
							})}
						</div>
					</div>
				</main>
			</section>
		</>
	);
}

export default SinglePlaylist;
