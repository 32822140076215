import React, { useEffect, useState } from "react";
import HeadingStrip from "../Common/HeadingStrip";
import BlogCard from "./BlogCard";
import "../../Assets/Css/blog.css";
import { getBlogs } from "../Api/Api";
import { Pagination } from "antd";
import { useTranslation } from "react-i18next";
import useLang from "../hooks/useLang";

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [page, setPage] = useState(0);
  const { t } = useTranslation(["main"]);
  const [totalCount, setTotalCount] = useState(0);
  const { currLang, currLangBackend } = useLang();

  const fetchBlogs = async (page) => {
    try {
      const res = await getBlogs(page);
      setBlogs(res.data.data);
      setTotalCount(res.data.pageLimit);
    } catch (e) {
      console.log(e);
    }
  };
  const handlePageChange = (clickedPage) => {
    fetchBlogs(clickedPage - 1);
    setPage(clickedPage - 1);
  };

  useEffect(() => {
    fetchBlogs(0);
  }, []);

  return (
    <>
      <section className="w-100 scroller blog-wrapper ps-3">
        <div className="w-100 d-flex justify-content-between headingStrip-wrapper py-lg-2 py-4 pe-lg-5 pe-2">
          <h4>{t("Blogs")}</h4>
          <div>
            <Pagination
              current={page + 1}
              total={totalCount}
              defaultPageSize={10}
              onChange={handlePageChange}
            />
          </div>
        </div>
        <main className="  d-flex flex-wrap">
          {blogs.map((item) => {
            return (
              <BlogCard
                key={item._id}
                id={item?._id}
                date={item?.date}
                tags={item[currLangBackend]?.tags}
                thumbnail={item?.thumbnail}
                author={item[currLangBackend]?.author}
                content={item[currLangBackend]?.content}
                title={item[currLangBackend]?.title}
              />
            );
          })}
        </main>
      </section>
    </>
  );
}

export default Blogs;
