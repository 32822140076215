import { useTranslation } from "react-i18next";

const convertCurrLangtoBackendField = {
  en: "englishInfo",
  hi: "hindiInfo",
  te: "teluguInfo",
  mr: "marathiInfo",
  ta: "tamilInfo",
  kr: "kannadInfo",
};

const useLang = () => {
  const { i18n } = useTranslation(["main"]);
  const currLang = i18n.language;
  const currLangBackend = convertCurrLangtoBackendField[i18n.language];
  return { currLang, currLangBackend };
};

export default useLang;
