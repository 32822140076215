/** @format */

import React, { useEffect, useState } from 'react';
import '../../Assets/Css/category.css';
import '../../Assets/Css/headingstrip.css';
import '../../Assets/Css/pagination.css';
import { getCategoriesList, getVideosByCategory } from '../Api/Api';
import { useTranslation } from 'react-i18next';
import Card from '../Common/Card';
import { Pagination } from 'antd';
import useLang from '../hooks/useLang';
import Dropdown from 'react-bootstrap/Dropdown';

function Categories() {
	const [categoriesList, setCategoriesList] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState('');
	const [videoList, setVideoList] = useState([]);
	const [page, setPage] = useState(0);
	const [totalCount, setTotalCount] = useState(0);
	const { t } = useTranslation(['main']);
	const { currLang, currLangBackend } = useLang();

	const handleCategory = (item) => {
		setSelectedCategory(item);
	};

	const fetchCategoriesList = async () => {
		try {
			const res = await getCategoriesList();
			setCategoriesList(res.data.category);
		} catch (e) {
			console.log(e);
		}
	};

	const fetchVideoByCategory = async (page) => {
		try {
			const res = await getVideosByCategory(page, selectedCategory?._id);
			setVideoList(res.data.data);

			setTotalCount(res.data.pageLimit);
		} catch (e) {
			console.log(e);
		}
	};
	const handlePageChange = (clickedPage) => {
		fetchVideoByCategory(clickedPage - 1);
		setPage(clickedPage - 1);
	};

	useEffect(() => {
		fetchCategoriesList();
	}, []);

	useEffect(() => {
		fetchVideoByCategory(0);
	}, [selectedCategory]);

	useEffect(() => {
		if (!selectedCategory && categoriesList.length > 0) {
			setSelectedCategory(categoriesList[0]);
		}
	}, [categoriesList]);

	console.log(categoriesList);
	return (
		<>
			<section className='category-wrapper d-flex'>
				<main className='categoryList '>
					<h5 className='p-3 pb-0'> {t('categories')} </h5>
					<hr />
					<ul className='px-2'>
						{/* <li
              className={` ${
                selectedCategory === "" ? "active-category" : ""
              }  `}
              onClick={() => handleCategory("")}
            >
              {t("All")}
            </li> */}
						<li>Hello</li>
						{categoriesList.map((item) => {
							return (
								<li
									key={item._id}
									onClick={() => handleCategory(item)}
									className={` ${
										selectedCategory._id === item._id ? 'active-category' : ''
									}  `}
								>
									{item[currLangBackend]}
								</li>
							);
						})}
					</ul>
				</main>
				<main className='category-card-wrapper px-4'>
					<Dropdown className='my-2 category-resp-dropdown'>
						<Dropdown.Toggle
							variant='white'
							className='w-100 border border-dark'
							id='dropdown-basic'
						>
							{t('categories')}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<li
								className={` ${
									selectedCategory === '' ? 'active-category' : ''
								}  `}
								onClick={() => handleCategory('')}
							>
								{t('All')}
							</li>
							{categoriesList.map((item, i) => {
								return (
									<li
										key={item._id}
										onClick={() => handleCategory(item)}
										className={`p-2  ${
											selectedCategory._id === item._id ? 'active-category' : ''
										}  `}
									>
										{item[currLangBackend]}
									</li>
								);
							})}
						</Dropdown.Menu>
					</Dropdown>
					{/* <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
					{/* <div >
            <button className="btn btn-primary" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">Toggle first element</button>
            <div>
              <div class="row">
                <div class="col">
                  <div class="collapse multi-collapse" id="multiCollapseExample1">
                    <div class="card card-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

					<div className='w-100 d-flex justify-content-between mb-3 mb-lg-0 headingStrip-wrapper py-2 pe-0 pe-lg-5'>
						<h4 className='mb-0 pb-0'>
							{t('categories')} :{' '}
							{selectedCategory === ''
								? `${t('')}`
								: selectedCategory[currLangBackend]}
						</h4>
						<div className='d-flex justify-content-center align-items-center'>
							<Pagination
								current={page + 1}
								total={totalCount}
								defaultPageSize={10}
								onChange={handlePageChange}
							/>
						</div>
					</div>

					<div className='d-flex flex-wrap'>
						{videoList.length === 0 ? (
							<h3 className='mt-4'>{t('')}</h3>
						) : (
							videoList.map((item, index) => {
								return (
									<Card
										index={index}
										key={index}
										id={item._id}
										duration={item.duration}
										date={item.date}
										uploadedBy={item.uploadedBy}
										url={item[currLangBackend]?.url}
										thumbnail={item[currLangBackend]?.thumbnail}
										title={item[currLangBackend]?.title}
										description={item[currLangBackend]?.description}
									/>
								);
							})
						)}
					</div>
				</main>
			</section>
		</>
	);
}

export default Categories;
