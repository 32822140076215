import { Tag } from "@mui/icons-material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShareIcon from "@mui/icons-material/Share";
import { Chip } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../Assets/Css/blog.css";
import { getBlog, likeBlog } from "../Api/Api";
import useLang from "../hooks/useLang";
function Singleblog() {
  const param = useParams();
  const currId = param?.id;
  const [info, setInfo] = useState({});
  const [copy, setCopy] = useState(false);
  const { currLang, currLangBackend } = useLang();

  const fetchBlog = async () => {
    try {
      const res = await getBlog(currId);
      setInfo(res.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchBlog();
  }, []);

  const handleLike = async () => {
    try {
      const res = await likeBlog({ _id: currId });
      fetchBlog();
    } catch (e) {
      console.log(e);
    }
  };
  const copyLink = (id) => {
    setCopy(true);
    navigator.clipboard.writeText(window.location.href);
    setTimeout(() => setCopy(false), 1500);
  };

  return (
    <>
      <section className="singleBlog-wrapper w-100 ">
        <main className="singleBlog-img-container">
          <img src={info?.thumbnail} alt="Blog Image" />
        </main>
        <div className="d-flex container my-4">
          {/* <Button
                        size="small"
                        className="text-dark d-flex align-items-center"
                        onClick={handleLike}
                    >
                        <FavoriteBorderIcon /> <span>{info?.likes || "Not present in DB"}</span>
                    </Button> */}
          <Button
            size="small"
            onClick={() => copyLink()}
            className={`text-dark  align-self-flex-end ${copy && "copied"}`}
          >
            <ShareIcon />
          </Button>
          {info[currLangBackend]?.tags?.map((tag) => (
            <div className="mx-2">
              <Chip
                label={tag}
                style={{ backgroundColor: "#6DC067", color: "#fff" }}
              />
            </div>
          ))}
        </div>

        <main className="container blog-wrapper-2">
          <p
            dangerouslySetInnerHTML={{ __html: info[currLangBackend]?.content }}
          />
        </main>
      </section>
    </>
  );
}

export default Singleblog;
