/** @format */

import axios from 'axios';

const API = axios.create({
	baseURL: 'https://academyapi.samunnati.com',
	//baseURL: 'http://localhost:5000',
});

API.interceptors.request.use((req) => {
	req.headers['ispublished'] = true;
	if (localStorage.getItem('accessToken')) {
		req.headers['token'] = `${localStorage.getItem('accessToken')}`;
	}
	return req;
});

API.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (
			error.response.data.code === 401 &&
			error.response.data.message === 'Authorizatin failed. Please sign in.'
		) {
			localStorage.removeItem('token');
			localStorage.removeItem('accessToken');
			// window.location.replace(`/login`);
		}
		if (
			error.response.status === 500 &&
			error.response.data.message ===
				'Error while verifying token: JsonWebTokenError: invalid token'
		) {
			localStorage.removeItem('token');
			localStorage.clear();
			window.location.replace(`/`);
			alert('Session Experied');
		}
		if (
			error.response.data.message ===
			'Error while verifying token: TokenExpiredError: jwt expired'
		) {
			localStorage.removeItem('token');
			localStorage.removeItem('accessToken');
			window.location.replace(`/login`);
		}
		console.log(error);
		return Promise.reject(error);
	}
);

export const PostUser = (data) => API.post('/api/user/signup', data);
export const LoginUser = (data) => API.post('/api/user/signin', data);
export const getFaqsApi = () => API.get('/api/user/faqs');

export const ResetPasswordApi = (token, data) =>
	API.patch(`api/user/resetPassword/${token}`, data);

export const GetUser = () => {
	return API.get('/api/user');
};

export const UpdateUser = (data) => {
	return API.put('/api/user/', data);
};

export const AddQuery = (data) => API.post('/api/user/query', data);

export const GetVideosById = (id) => API.get(`/api/user/video/${id}`);

export const getCategoriesList = () => API.get(`api/admin/category`);

export const getVideosByCategory = (page, category) => {
	return API.get(`api/user/video/category`, {
		params: {
			videoIndex: page,
			category: category,
		},
	});
};

export const getBlogs = (page) => {
	return API.get(`api/user/blog`, {
		params: {
			blogIndex: page,
		},
	});
};

export const getBlog = (id) => {
	return API.get(`api/user/blog/${id}`);
};

export const getPlaylists = (lng) => {
	return API.get(`api/user/video/playlists?language=${lng}`);
};

export const getPlaylist = (playlist, page) => {
	return API.get(`api/user/video/playlist`, {
		params: {
			playlist: playlist,
			videoIndex: page,
		},
	});
};

export const getPlaylistByTitle = (title) => {
	return API.get(`api/user/video/playlist/title?title=${title}`);
};
export const getVideoByTitle = (title) => {
	return API.get(`api/user/video/title?title=${title}`);
};

export const getFeaturedVideos = (page, currLang, limit) => {
	return API.get(`api/user/video/featured`, {
		params: {
			videoIndex: page,
			language: currLang,
			limit: limit,
			isPublished: true,
		},
	});
};

export const getLikedVideos = (page, currLang, userId, limit) => {
	return API.get(`/api/user/video/likedVideos?userId=${userId}`, {
		params: {
			videoIndex: page,
			language: currLang,
			limit: limit,
		},
	});
};
export const getContinueWatching = (page, currLang) => {
	return API.get(`/api/user/video/continueWatching`, {
		params: {
			videoIndex: page,
			language: currLang,
		},
	});
};

export const getMostViewedVideos = (page, currLang, limit) => {
	return API.get(`api/user/video/mostViewed`, {
		params: {
			videoIndex: page,
			language: currLang,
			limit: limit,
			isPublished: true,
		},
	});
};
export const getAllVideos = (page, currLang, limit) => {
	return API.get(`api/user/video/getAll`, {
		params: {
			videoIndex: page,
			language: currLang,
			limit: limit,
			isPublished: true,
			sortBy: 'date',
		},
	});
};

export const likeBlog = (values) => {
	return API.put(`api/user/blog`, values);
};

export const likeVideo = (values) => {
	return API.put(`api/user/videoStat`, values);
};

export const forgotPassword = (values) => {
	return API.post(`api/user/forgotPassword`, values);
};

export const uploadPhoto = async (values) => {
	const data = await axios.post(
		'https://uploader.viosa.in/api/v2/samunnati/upload/file',
		values
	);
	return data?.data?.link;
};

export const searchVideo = (searchValue) => {
	return API.get('api/user/video/search', {
		params: {
			keyword: searchValue,
		},
	});
};
export const searchBlog = (searchValue) => {
	return API.get('api/user/blog/search', {
		params: {
			keyword: searchValue,
		},
	});
};
export const searchPlaylist = (searchValue) => {
	return API.get('api/user/playlist/search', {
		params: {
			keyword: searchValue,
		},
	});
};

export const postDurationStats = (values) => {
	return API.post('api/user/video/watch', values);
};
export const getAllNotification = () => {
	return API.get('/api/user/notification');
};
export const getAllNotificationPanel = () => {
	return API.get('/api/user/notificationPanel');
};
