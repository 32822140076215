// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/** @format */\nbody {\n\tpadding: 0;\n\tbox-sizing: border-box;\n\tfont-family: 'Montserrat', sans-serif !important;\n}\n\n.scroller::-webkit-scrollbar {\n\twidth: 8px;\n}\n\n.logo-img {\n\twidth: auto;\n\theight: 50px;\n}\n\np {\n\tmargin-bottom: 0;\n}\n/* Track */\n.scroller::-webkit-scrollbar-track {\n\tbackground: #dad8d8;\n}\n\n/* Handle */\n.scroller::-webkit-scrollbar-thumb {\n\tbackground: rgb(177, 175, 175);\n\tborder-radius: 20px;\n}\n\n@media (max-width: 768px) {\n\t.mobile-notification {\n\t\twidth: 85%;\n\t}\n\t.mobile-screen-playlist {\n\t\tpadding-left: 35px;\n\t}\n}\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,aAAa;AAGb;CACC,UAAU;CACV,sBAAsB;CACtB,gDAAgD;AACjD;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,WAAW;CACX,YAAY;AACb;;AAEA;CACC,gBAAgB;AACjB;AACA,UAAU;AACV;CACC,mBAAmB;AACpB;;AAEA,WAAW;AACX;CACC,8BAA8B;CAC9B,mBAAmB;AACpB;;AAEA;CACC;EACC,UAAU;CACX;CACA;EACC,kBAAkB;CACnB;AACD","sourcesContent":["/** @format */\n\n@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');\nbody {\n\tpadding: 0;\n\tbox-sizing: border-box;\n\tfont-family: 'Montserrat', sans-serif !important;\n}\n\n.scroller::-webkit-scrollbar {\n\twidth: 8px;\n}\n\n.logo-img {\n\twidth: auto;\n\theight: 50px;\n}\n\np {\n\tmargin-bottom: 0;\n}\n/* Track */\n.scroller::-webkit-scrollbar-track {\n\tbackground: #dad8d8;\n}\n\n/* Handle */\n.scroller::-webkit-scrollbar-thumb {\n\tbackground: rgb(177, 175, 175);\n\tborder-radius: 20px;\n}\n\n@media (max-width: 768px) {\n\t.mobile-notification {\n\t\twidth: 85%;\n\t}\n\t.mobile-screen-playlist {\n\t\tpadding-left: 35px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
