/** @format */

import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import '../../Assets/Css/login.css';
import { useNavigate } from 'react-router-dom';
import logo from '../../Assets/Images/newlogo.png';
import { forgotPassword, LoginUser } from '../Api/Api';

function ForgotPassword() {
	const { t } = useTranslation(['main']);
	const [spinn, setspinn] = useState(false);
	const [email, setEmail] = useState('');
	const navigate = useNavigate();

	const handleSubmit = async () => {
		if (!email) {
			toast.error('Fill Email !');
			return;
		}
		setspinn(true);
		try {
			const data = await forgotPassword({ email: email });
			toast.success(
				'Check your email, we sent you a link to reset your password'
			);
			setspinn(false);
		} catch (error) {
			console.log(error);
			toast.error(error.response.data.message);
		} finally {
			setspinn(false);
		}
	};

	return (
		<>
			<Toaster position='top-right' />
			<section
				className='d-flex justify-content-center align-items-center w-100 '
				style={{ height: '100vh' }}
			>
				<form className='loginform-wrapper p-3'>
					<div className='d-flex justify-content-center pb-2'>
						<img src={logo} alt='No-Img' className='logo-img' />
					</div>

					<p className='text-center py-4'>{t('Forgot Password Note')} </p>

					<div className='form-outline mb-4'>
						<label className='form-label' for='form2Example1'>
							{t('Email')}{' '}
						</label>
						<input
							type='email'
							id='form2Example1'
							onChange={(e) => setEmail(e.target.value)}
							name='email'
							value={email}
							className='form-control'
						/>
					</div>
					<button
						type='button'
						onClick={handleSubmit}
						className='btn text-white  w-100 py-2 mb-4'
						style={{ backgroundColor: 'rgba(4, 195, 92, 1)' }}
					>
						{!spinn && t('Send Password')}
						{spinn ? (
							<div
								class='spinner-border mx-2 spinner-border-sm text-light'
								role='status'
							>
								<span class='sr-only'></span>
							</div>
						) : null}
					</button>
					<button
						type='button'
						className='btn text-white  w-100 py-2 mb-4'
						onClick={() => navigate('/login')}
						style={{ backgroundColor: '#007aed' }}
					>
						{t('Login into your account')}
					</button>
				</form>
			</section>
		</>
	);
}

export default ForgotPassword;
