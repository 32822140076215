/** @format */

import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import '../../Assets/Css/searchresult.css';
import { searchBlog, searchPlaylist, searchVideo } from '../Api/Api';
import { BLOGS, PLAYLISTS, VIDEOS } from '../Helper/types';
import Card from '../Common/Card';
import useLang from '../hooks/useLang';
import PlaylistCard from '../Playlist/PlaylistCard';
import BlogCard from '../Blogs/BlogCard';

const SearchResult = () => {
	const { query } = useParams();
	const [selectedField, setSelectedField] = useState(VIDEOS);
	const [resultCount, setResultCount] = useState(0);
	const [videos, setVideos] = useState([]);
	const [blogs, setBlogs] = useState([]);
	const [playlists, setPlaylist] = useState([]);
	const { currLang, currLangBackend } = useLang();

	const fetchVideos = async () => {
		try {
			const res = await searchVideo(query);
			setResultCount(res?.data?.result?.length);
			setVideos(res?.data?.result);
		} catch (e) {
			console.log(e);
			toast.error(e.response.data.message);
		}
	};
	const fetchBlogs = async () => {
		try {
			const res = await searchBlog(query);
			setResultCount(res?.data?.result?.length);
			setBlogs(res?.data?.result);
		} catch (e) {
			console.log(e);
			toast.error(e.response.data.message);
		}
	};
	const fetchPlaylists = async () => {
		try {
			const res = await searchPlaylist(query);
			setResultCount(res?.data?.result?.length);
			setPlaylist(res?.data?.result);
		} catch (e) {
			console.log(e);
			toast.error(e.response.data.message);
		}
	};

	const handleChange = (type) => {
		if (selectedField === type) return;
		if (type === VIDEOS) {
			setSelectedField(VIDEOS);
			fetchVideos();
		} else if (type === BLOGS) {
			setSelectedField(BLOGS);
			fetchBlogs();
		} else if (type === PLAYLISTS) {
			setSelectedField(PLAYLISTS);
			fetchPlaylists();
		}
	};

	useEffect(() => {
		fetchVideos();
	}, []);

	return (
		<>
			<Toaster position='top-right' />
			<section className='search-result-container ps-3'>
				<main className='search-result-wrapper scroller'>
					<div className='mb-5'>
						<div className='w-100 d-flex justify-content-between search-item py-2 pe-5'>
							<h2>
								Found {resultCount} Results for "{query}"
							</h2>
							<div className='search-btn'>
								<button
									className={`search-item-btn ${
										selectedField === VIDEOS && 'search-item-btn-selected'
									}`}
									onClick={() => handleChange(VIDEOS)}
								>
									Videos
								</button>
								<button
									className={`search-item-btn ${
										selectedField === BLOGS && 'search-item-btn-selected'
									}`}
									onClick={() => handleChange(BLOGS)}
								>
									Blogs
								</button>
								<button
									className={`search-item-btn ${
										selectedField === PLAYLISTS && 'search-item-btn-selected'
									}`}
									onClick={() => handleChange(PLAYLISTS)}
								>
									Playlists
								</button>
							</div>
						</div>
						<div className='d-flex flex-wrap'>
							{/* Videos */}
							{selectedField === VIDEOS
								? videos?.map((item, index) => {
										return (
											<Card
												index={index}
												key={item._id}
												id={item._id}
												urltitle={item.urltitle}
												duration={item.duration}
												date={item.date}
												uploadedBy={item.uploadedBy}
												url={item[currLangBackend]?.url}
												thumbnail={item[currLangBackend]?.thumbnail}
												title={item[currLangBackend]?.title}
												description={item[currLangBackend]?.description}
											/>
										);
								  })
								: null}
							{/* Blogs */}
							{selectedField === BLOGS
								? blogs?.map((item) => {
										return (
											<BlogCard
												key={item._id}
												id={item?._id}
												date={item?.date}
												thumbnail={item?.thumbnail}
												author={item[currLangBackend]?.author}
												content={item[currLangBackend]?.content}
												title={item[currLangBackend]?.title}
											/>
										);
								  })
								: null}
							{/* Playlist */}
							{selectedField === PLAYLISTS
								? playlists.map((item) => {
										return <PlaylistCard key={item._id} item={item} />;
								  })
								: null}
						</div>
					</div>
				</main>
			</section>
		</>
	);
};

export default SearchResult;
