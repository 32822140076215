/** @format */

import ShareIcon from '@mui/icons-material/Share';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
// import "video-player-for-react/dist/index.css";
// import "video.js/dist/video-js.css";
import 'videojs-plus/dist/plugins/unload';
import '../../Assets/Css/singlevideo.css';
import channelLogo from '../../Assets/Images/cardImage.jpg';
import {
	GetVideosById,
	getVideoByTitle,
	likeVideo,
	postDurationStats,
} from '../Api/Api';
import useLang from '../hooks/useLang';
import Player from './Player';
import { useRef } from 'react';
import { percent } from '../Helper/helperFunctions';
import useAuth from '../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const playerOptions = {};

function SingleVideo() {
	const param = useParams();
	const { auth } = useAuth();
	const navigate = useNavigate();
	const urltitle = param?.id;
	const [currId, setCurrId] = useState('');
	const [copy, setCopy] = useState(false);
	const [videoData, setVideoData] = useState({});
	const [player, setPlayer] = useState(null);
	const [video, setVideo] = useState(null);
	const { t } = useTranslation(['main']);
	const [isLike, setIsLike] = useState(videoData?.liked);
	const [likeCount, setLikeCount] = useState(videoData?.videoStatId?.likes);
	const { currLang, currLangBackend } = useLang();
	const [playlist, setPlaylist] = useState('Home');
	let intervalRef = useRef(null);

	const fetchId = async () => {
		try {
			const data = await getVideoByTitle(urltitle);
			setCurrId(data?.data?.result?._id);
		} catch (error) {
			console.log(error);
			toast.error(error?.response?.data?.message);
		}
	};

	const getData = async () => {
		try {
			const data = await GetVideosById(currId);
			setVideoData(data?.data?.data);
		} catch (error) {
			console.log(error);
			toast.error(error?.response?.data?.message);
		}
	};

	useEffect(() => {
		console.log(videoData[currLangBackend]?.url);
		setVideo({
			sources: [
				{
					src: videoData[currLangBackend]?.url,
					type: 'video/mp4',
				},
			],
		});
	}, [videoData, currLangBackend]);

	const handleStats = async (time) => {
		// console.log(time, videoData);
		try {
			const data = await postDurationStats({
				videoId: videoData?._id,
				lastWatched: time,
				completelyWatched:
					videoData?.progress?.completelyWatched ||
					percent(time, videoData?.duration) > 80,
			});
		} catch (error) {
			console.log(error);
			toast.error(error?.response?.data?.message);
		}
	};
	useEffect(() => {
		if (player && currId !== '') {
			player.unload({ loading: true });
			getData();
		}
	}, [player, currId]);

	useEffect(() => {
		fetchId();
	}, []);

	useEffect(() => {
		setIsLike(videoData?.liked);
		setLikeCount(videoData?.videoStatId?.likes);
	}, [videoData]);

	useEffect(() => {
		if (player && video) {
			player.src(video.sources);
		}
	}, [video, player]);

	function onPlayerEnded() {
		clearInterval(intervalRef.current);
	}

	useEffect(() => {
		if (player) {
			player.on('ended', onPlayerEnded);

			player.on('loadedmetadata', function () {
				player.currentTime(videoData?.progress?.lastWatched);
			});

			return () => {
				player.off('ended', onPlayerEnded);
			};
		}
	}, [player, videoData]);

	useEffect(() => {
		if (player && videoData.duration && !intervalRef.current) {
			intervalRef.current = setInterval(() => {
				auth && handleStats(player.cache_.currentTime);
			}, [6000]);
		}
		return () => {
			clearInterval(intervalRef.current);
		};
	}, [player, videoData, auth]);

	const handleLike = async () => {
		if (auth) {
			try {
				const res = await likeVideo({ videoId: currId, action: '0' });
				if (res.data.message === 'like') {
					setLikeCount((prev) => prev + 1);
					setIsLike(true);
				}
				if (res.data.message === 'dislike') {
					setLikeCount((prev) => prev - 1);
					setIsLike(false);
				}
			} catch (e) {
				console.log(e);
			}
		} else {
			navigate('/login');
		}
	};

	const copyLink = () => {
		setCopy(true);
		navigator.clipboard.writeText(window.location.href);
		setTimeout(() => setCopy(false), 1500);
	};

	useEffect(() => {
		if (videoData?.playlist) {
			setPlaylist(videoData?.playlist[currLangBackend]);
		} else {
			setPlaylist('Home');
		}
	}, [videoData, currLangBackend]);

	return (
		<>
			<Helmet>
				<title>{videoData?.englishInfo?.title}</title>
				<meta property='og:title' content={videoData?.englishInfo?.title} />
				<meta
					property='og:description'
					content={videoData?.englishInfo?.description}
				/>
				<meta property='og:image' content={videoData?.englishInfo?.thumbnail} />
				{/* <meta property='og:video' content={videoData?.englishInfo?.url} />
				<meta property='og:video:type' content='video/mp4' />
				<meta property='og:video:width' content='640' />
				<meta property='og:video:height' content='480' /> */}

				<meta name='twitter:card' content='summary_large_image' />
				<meta name='twitter:title' content={videoData?.englishInfo?.title} />
				<meta
					name='twitter:description'
					content={videoData?.englishInfo?.description}
				/>
				<meta
					name='twitter:image'
					content={videoData?.englishInfo?.thumbnail}
				/>
			</Helmet>
			<section className='flex-column container-fluid px-0 w-100 '>
				<div>
					<Player
						playerOptions={playerOptions}
						onPlayerInit={setPlayer}
						onPlayerDispose={setPlayer}
					/>
				</div>

				<main className='video-detail-wrapper container py-5 '>
					<div className='col-12 col-lg-10'>
						<div className='flex-column'>
							<div className='d-flex card-custom-body'>
								<h4 className='px-2'>
									{playlist === 'Home' ? (
										<>
											<span onClick={() => navigate(`/`)}>Home</span> :&nbsp;
										</>
									) : (
										<>
											<span
												onClick={() =>
													navigate(`/playlist/${videoData?.playlist._id}`)
												}
											>
												{playlist}
											</span>{' '}
											:&nbsp;
										</>
									)}
									{videoData[currLangBackend]?.title}
								</h4>
							</div>
							<div className='d-flex justify-content-end video-detail-head '>
								<div className='d-flex my-2 my-lg-0'>
									<Button
										className='me-lg-5 me-2 align-items-center'
										onClick={handleLike}
									>
										{isLike ? <ThumbUpIcon /> : <ThumbUpOffAltIcon />}{' '}
										{likeCount}
									</Button>
									<Button
										className={`${copy && 'copied'}`}
										onClick={() => copyLink()}
										size='small text-dark  align-self-flex-end'
									>
										<ShareIcon />
									</Button>
									{/* <span className='align-items-center'><ThumbDownOffAltIcon /> Dislike</span> */}
								</div>
							</div>
							<p>
								{videoData?.videoStatId?.views} views | {videoData?.date}
							</p>
						</div>

						<hr />

						<div className='video-description'>
							<figure className='d-flex align-items-center'>
								<img src={channelLogo} alt='Noimg' />
								<figcaption> {videoData?.uploadedBy}</figcaption>
							</figure>
							<p>{videoData[currLangBackend]?.description}</p>
						</div>
					</div>
				</main>
			</section>
		</>
	);
}

export default SingleVideo;
