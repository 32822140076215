import React, { useState } from "react";
import HeadingStrip from "../Common/HeadingStrip";
import "../../Assets/Css/faq.css";
import { useTranslation } from "react-i18next";

import CustomizedAccordions from "./Accordian";
import { getFaqsApi } from "../Api/Api";
import { useEffect } from "react";
import useLang from "../hooks/useLang";
function Faq() {
  const [faqs, setFaqs] = useState([]);
  const { t } = useTranslation(["main"]);
  const { currLang, currLangBackend } = useLang();
  console.log(currLang, currLangBackend);
  const getFaqs = async () => {
    try {
      const data = await getFaqsApi();
      setFaqs(data?.data?.data || []);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getFaqs();
  }, []);
  return (
    <>
      <section className="faq-wrapper ps-3">
        <HeadingStrip head={"Frequently asked questions"} sub={false} />

        <main className="faq-content mt-4 col-12 col-lg-7">
          {faqs?.map((fa, index) => (
            <div className="mb-3 w-100">
              <CustomizedAccordions
                question={fa[currLangBackend]?.question}
                answer={fa[currLangBackend]?.answer}
              />
            </div>
          ))}
        </main>
      </section>
    </>
  );
}

export default Faq;
