import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import "../../Assets/Css/profile.css";
import { GetUser, UpdateUser, uploadPhoto } from "../Api/Api";
import useAuth from "../hooks/useAuth";
import userAvatar from "../../Assets/Images/user.jpg";
import { useTranslation } from "react-i18next";
import useLang from "../hooks/useLang";

function EditProfile() {
  const navigate = useNavigate();
  const { t } = useTranslation(["main"]);
  const { auth, user } = useAuth();
  const [profileData, setprofileData] = useState({});
  const [imgFile, setImgFile] = useState({});
  const [profilePic, setProfilePic] = useState("");
  const [loading, setLoading] = useState(false);
  const [isUpload, setIsUpload] = useState(false);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setprofileData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  useEffect(() => {
    const call = async () => {
      try {
        const data = await GetUser();
        console.log(data);
        setprofileData(data?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };
    call();
  }, []);

  const selectImage = (e) => {
    e.preventDefault();
    const imgFile = e.target.files[0];
    setImgFile(imgFile);
  };

  const uploadImg = async () => {
    if (!imgFile || !imgFile.name) {
      toast.error("Choose image!");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("file", imgFile);
    let imageUrl;
    try {
      imageUrl = await uploadPhoto(formData);
      setLoading(false);
      setIsUpload(true);
      setProfilePic(imageUrl);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async () => {
    try {
      let newData = {
        ...profileData,
        profile: profilePic,
      };
      if (!imgFile || !imgFile.name) {
        delete newData.profile;
      }
      const updateData = await UpdateUser(newData);
      navigate("/");
      setIsUpload(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Toaster position="top-right" />
      <section className="editProfile-wrapper scroller  w-100 ps-3 py-3">
        <h4>{t("Edit Profile")}</h4>
        <h5>{t("Update your profile. Click on save once done.")}</h5>
        <div className="d-flex flex-column">
          <div className="d-flex">
            <div className="col-9 col-lg-8">
              <div className="form-outline mb-4">
                <div className="upload-Image">
                  <div className="col-6">
                    <label className="form-label" htmlFor="form2Example1">
                      {t("Profile Picture")}{" "}
                    </label>
                    <input
                      type="file"
                      onChange={selectImage}
                      className="form-control"
                    />
                  </div>
                  <button
                    className="btn btn-warning text-white"
                    onClick={() => uploadImg()}
                  >
                    {loading
                      ? "Uploading..."
                      : isUpload
                      ? "Uploaded"
                      : `${t("Upload")}`}
                  </button>
                </div>
              </div>
              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="form2Example1">
                  {t("Name")}
                </label>
                <input
                  type="text"
                  id="form2Example1"
                  onChange={handleInput}
                  name="name"
                  defaultValue={profileData?.name}
                  placeholder={t("Enter your name")}
                  className="form-control"
                />
              </div>
              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="form2Example1">
                  {t("Email")}{" "}
                </label>
                <input
                  type="email"
                  id="form2Example1"
                  onChange={handleInput}
                  defaultValue={profileData?.email}
                  placeholder={t("Email")}
                  name="email"
                  className="form-control"
                />
              </div>
              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="form2Example1">
                  {t("Phone")}{" "}
                </label>
                <input
                  type="tel"
                  id="form2Example1"
                  onChange={handleInput}
                  defaultValue={profileData?.phone}
                  name="phone"
                  placeholder={t("Phone")}
                  className="form-control"
                />
              </div>
              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="form2Example1">
                  {t("Age")}
                </label>
                <input
                  type="number"
                  id="form2Example1"
                  onChange={handleInput}
                  defaultValue={profileData?.age}
                  name="age"
                  placeholder={t("Age")}
                  className="form-control"
                />
              </div>
              <div className="form-outline mb-4">
                <label className="form-label">{t("Designation")}</label>
                <input
                  className="form-control"
                  id="form2Example1"
                  onChange={handleInput}
                  defaultValue={profileData?.designation}
                  name="designation"
                  placeholder={t("Designation")}
                  aria-label=".form-select-lg example"
                />
              </div>
              <div className="form-outline mb-4">
                <label className="form-label">{t("Organisation")}</label>
                <input
                  className="form-control"
                  onChange={handleInput}
                  placeholder={t("Organisation")}
                  defaultValue={profileData?.organisation}
                  id="form2Example1"
                  name="organisation"
                  aria-label=".form-select-lg example"
                />
              </div>
              <button
                className="btn btn-warning text-white"
                onClick={handleSubmit}
              >
                {t("Submit")}
              </button>
            </div>
            <div className="col-3 my-5">
              <div className="d-flex justify-content-center align-items-center flex-column">
                <b className="mb-2">{t("Current Profile Picture")}:</b>
                <img
                  src={profileData?.profile || userAvatar}
                  alt="logo"
                  style={{ width: "180px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EditProfile;
