/** @format */

import React, { useEffect, useState } from 'react';
import '../../Assets/Css/playlistcard.css';
import cardImage from '../../Assets/Images/cardImage.jpg';
import { useNavigate } from 'react-router-dom';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import { formatSecondsOtherFormat } from '../Helper/helperFunctions';
import useLang from '../hooks/useLang';

function PlaylistCard({ item }) {
	const navigate = useNavigate();
	const { currLang, currLangBackend } = useLang();
	const [thumbnail, setthumbnail] = useState('en');
	useEffect(() => {
		if (currLang === 'en') {
			setthumbnail(item?.playlist?.englishThumbnail);
		} else if (currLang === 'hi') {
			setthumbnail(item?.playlist?.hindiThumbnail);
		} else if (currLang === 'mr') {
			setthumbnail(item?.playlist?.marathiThumbnail);
		} else if (currLang === 'kr') {
			setthumbnail(item?.playlist?.kannadThumbnail);
		} else if (currLang === 'ta') {
			setthumbnail(item?.playlist?.tamilThumbnail);
		} else if (currLang === 'te') {
			setthumbnail(item?.playlist?.teluguThumbnail);
		}
	}, [currLang]);

	return (
		<>
			<div
				onClick={() =>
					navigate(`/playlist/${item?.playlist.urltitle}`, {
						state: { playlistName: item?.playlist[currLangBackend] },
					})
				}
				className='card position-relative playlist-card me-4 mb-4 '
				style={{ cursor: 'pointer' }}
			>
				<img
					className='card-img-top'
					src={thumbnail ? thumbnail : item?.thumbnail}
					alt='Card image cap'
				/>
				<div className='playlistcard-video-size'>
					<span>{item?.totalVideos}</span>
					<LiveTvIcon />
				</div>
				<div className='card-body playlistcard-custom-body'>
					<h5>{item?.playlist[currLangBackend]}</h5>

					{/* <p className="card-text my-2">How to process with tree grafting lorem without damaging</p> */}

					<div className='d-flex my-3 justify-content-between upload-details'>
						<p className='upload-Text'>{`${item?.totalVideos} ${
							item?.totalVideos > 1 ? 'Videos' : 'Video'
						}`}</p>
						<p>{formatSecondsOtherFormat(item?.totalDuration)}</p>
					</div>
					{/* <button className='btn btn-sm my-2 btn-outline-primary'>45% Complete</button> */}
				</div>
			</div>
		</>
	);
}

export default PlaylistCard;
