import "./skelton.css";
export default function Skeleton({ type }) {
  const COUNTER = 4;
  const CourseSkelton = () => (
    <div
      className="card position-relative me-4 mb-4 card-container-skelton"
      style={{ cursor: "pointer" }}
    >
      <div className="position-relative">
        <div className="skelton-card-img-top" alt="Card image cap" />
        <span className="skelton-card-video-length"></span>
      </div>

      <div className="skelton-card-body skelton-card-custom-body">
        <h5></h5>
        <p> </p>
        <p> </p>
      </div>
    </div>
  );
  return Array(COUNTER).fill(<CourseSkelton />);
}
