import ArticleIcon from "@mui/icons-material/Article";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ListIcon from "@mui/icons-material/List";
import PhoneIcon from "@mui/icons-material/Phone";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import HomeIcon from "@mui/icons-material/Home";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "../../Assets/Css/sidebar.css";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";

import Fade from "@mui/material/Fade";

function Sidebar() {
  const { t } = useTranslation(["main"]);
  const location = useLocation();

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  // if (location.pathname === "/") {
  //   return null;
  // }

  return (
    <>
      <aside className="col-lg-1 col-12 col-sm-2 mx-sm-3 sidebar-wrapper mx-lg-3 mt-4">
        <div className="sidebar-content">
          {/* video buttons  */}

          <nav className="sidebar-content-nav">
            <Link
              to={"/home"}
              className={`sidebar-content-links ${location.pathname === "/" ||
                  location.pathname.includes("/playlist")
                  ? "active-block"
                  : null
                } `}
            >
              <SubscriptionsIcon
                className={`${location.pathname.length === 1 ||
                    location.pathname.includes("/playlist")
                    ? "active-icon"
                    : null
                  }`}
              />
              <h6>{t("playlist")}</h6>
            </Link>

            {/* <Link
              to={"/categories"}
              className={`sidebar-content-links ${
                location.pathname === "/categories" ? "active-block" : null
              } `}
            >
              <ListIcon
                className={`${
                  location.pathname === "/categories" ? "active-icon" : null
                }`}
              />
              <h6>{t("categories")}</h6>
            </Link> */}
            <Link
              to={"/videos"}
              className={`sidebar-content-links ${location.pathname === "/videos" ? "active-block" : null
                } `}
            >
              <OndemandVideoIcon
                className={`${location.pathname === "/videos" ? "active-icon" : null
                  }`}
              />
              <h6>{t("home")}</h6>
            </Link>
            <Link
              to={"/blogs"}
              className={`sidebar-content-links blog-resp  ${location.pathname === "/blogs" ? "active-block" : null
                } `}
            >
              <ArticleIcon
                className={`${location.pathname === "/blogs" ? "active-icon" : null
                  }`}
              />
              <h6>{t("Blogs")}</h6>
            </Link>

            <div
              className={`sidebar-content-links more-resp  ${location.pathname === "/blogs" ? "active-block" : null
                } `}
              aria-describedby={id}
              type="button"
              onClick={handleClick}
            >
              <KeyboardArrowUpIcon
                className={`${location.pathname === "/blogs" ? "active-icon" : null
                  }`}
              />
              <h6>{t("More")}</h6>
            </div>
          </nav>

          {/* query buttons */}

          <nav className="sidebar-content-help-nav">
            <Link
              to={"/query"}
              className={`sidebar-content-help-links ${location.pathname === "/query" ? "active-block" : null
                } `}
            >
              <QuestionAnswerIcon
                className={`${location.pathname === "/query" ? "active-icon" : null
                  }`}
              />
              <h6>{t("Query")}</h6>
            </Link>
            {/* <Link
              to={"/faq"}
              className={`sidebar-content-help-links ${
                location.pathname === "/faq" ? "active-block" : null
              } `}
            >
              <ContactSupportIcon
                className={`${
                  location.pathname === "/faq" ? "active-icon" : null
                }`}
              />
              <h6 className="text-center">{t("Faq")}</h6>
            </Link> */}
            {/* <Link
              to={"/contactus"}
              className={`sidebar-content-help-links ${
                location.pathname === "/contactus" ? "active-block" : null
              } `}
            >
              <PhoneIcon
                className={`${
                  location.pathname === "/contactus" ? "active-icon" : null
                }`}
              />
              <h6>{t("Contact us")}</h6>
            </Link> */}
          </nav>
        </div>
      </aside>

      {/* //popper */}
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={"top-end"}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div className="more-content-resp">
              <Link
                to={"/blogs"}
                className={`sidebar-content-help-links ${location.pathname === "/blogs" ? "active-block" : null
                  } `}
              >
                <ArticleIcon
                  className={`${location.pathname === "/blogs" ? "active-icon" : null
                    }`}
                />
                <h6>{t("Blogs")}</h6>
              </Link>

              <Link
                to={"/query"}
                className={`sidebar-content-help-links ${location.pathname === "/query" ? "active-block" : null
                  } `}
              >
                <QuestionAnswerIcon
                  className={`${location.pathname === "/query" ? "active-icon" : null
                    }`}
                />
                <h6>{t("Query")}</h6>
              </Link>
              {/* <Link
                to={"/faq"}
                className={`sidebar-content-help-links ${
                  location.pathname === "/faq" ? "active-block" : null
                } `}
              >
                <ContactSupportIcon
                  className={`${
                    location.pathname === "/faq" ? "active-icon" : null
                  }`}
                />
                <h6 className="text-center">{t("Faq")}</h6>
              </Link> */}
              {/* <Link
                to={"/contactus"}
                className={`sidebar-content-help-links ${
                  location.pathname === "/contactus" ? "active-block" : null
                } `}
              >
                <PhoneIcon
                  className={`${
                    location.pathname === "/contactus" ? "active-icon" : null
                  }`}
                />
                <h6>{t("Contact us")}</h6>
              </Link> */}
            </div>
          </Fade>
        )}
      </Popper>
    </>
  );
}

export default Sidebar;
