import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../Assets/Css/playlist.css";
import useLang from "../../hooks/useLang";

function RelatedCards({ item, videoId, playlistId }) {
  const [playing, setPlaying] = useState(false);
  const navigate = useNavigate();
  const { currLang, currLangBackend } = useLang();

  const nowPlaying = () => {
    if (videoId === item?._id) {
      setPlaying(true);
    } else {
      setPlaying(false);
    }
  };

  useEffect(() => {
    nowPlaying();
  }, [item?._id, videoId]);

  return (
    <>
      <div
        onClick={() => {
          navigate(`/playlist/${playlistId}/video/${item?._id}`);
        }}
        className={`d-flex playlist-related-card pointer ${
          playing ? "now_playing" : ""
        }`}
      >
        <img
          src={item[currLangBackend]?.thumbnail || item?.englishInfo?.thumbnail}
          alt=""
        />
        <div className="playlist-related-card-content p-3">
          <h6>{item[currLangBackend]?.title || item?.englishInfo?.title}</h6>
          {/* <p>{item.uploadedBy}</p> */}
          <p>
            {item?.videoStatId?.views} views | {item?.date}
          </p>
        </div>
      </div>
    </>
  );
}

export default RelatedCards;
