/** @format */

import React, { useState, useEffect } from 'react';
import '../../Assets/Css/home.css';
import '../../Assets/Css/headingstrip.css';
import { Pagination } from 'antd';
import Card from '../Common/Card';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import {
	getAllNotification,
	getAllVideos,
	getContinueWatching,
	getFeaturedVideos,
	getLikedVideos,
	getMostViewedVideos,
} from '../Api/Api';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
	ALL_VIDEOS,
	LIKED_VIDEOS,
	CONTINUE_WATCHING,
	FEATURED_VIDEOS,
	HOME_VIDEOS,
	MOST_VIEWED_VIDEOS,
} from '../Helper/types';
import useLang from '../hooks/useLang';
import useAuth from '../hooks/useAuth';
import { percent } from '../Helper/helperFunctions';
import Skeleton from '../Common/Skelton';
import Marquee from 'react-fast-marquee';
import { Notification } from './Notification';

function Home() {
	//state for display
	const userId = localStorage.getItem('userId');
	const [currDisplay, setCurrDisplay] = useState(HOME_VIDEOS);
	const [loading, setLoading] = useState(false);
	//state for videos
	const [featuredVideos, setfeaturedVideos] = useState([]);
	const [mostViewedVideos, setMostViewedVideos] = useState([]);
	const [allVideos, setAllVideos] = useState([]);
	const [likedVideos, setLikedVideos] = useState([]);
	const [continueVideos, setContinueVideos] = useState([]);

	// States for pagination
	const [featuredPage, setFeaturedPage] = useState(0);
	const [mostViewedPage, setMostViewedPage] = useState(0);
	const [allVideoPage, setAllVideoPage] = useState(0);
	const [likedVideoPage, setlikedVideoPage] = useState(0);
	const [continueVideoPage, setContinueVideoPage] = useState(0);
	const [totalFeaturedCount, setTotalFeaturedCount] = useState(0);
	const [totalMostViewedCount, setTotalMostViewedCount] = useState(0);
	const [totalAllVideoCount, setTotalAllVideoCount] = useState(0);
	const [totalLikedVideoCount, setTotalLikedVideoCount] = useState(0);
	const [totalContinueVideoCount, setTotalContinueVideoCount] = useState(0);

	const [limit, setLimit] = useState(10);
	const { t } = useTranslation(['main']);
	const { currLang, currLangBackend } = useLang();
	const { auth } = useAuth();

	const fetchFeaturedVideos = async (page) => {
		setLoading(true);
		try {
			const data = await getFeaturedVideos(page, currLang, limit);
			setfeaturedVideos(data?.data?.data);
			setTotalFeaturedCount(data?.data?.pageLimit);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};
	const fetchMostViewedVideos = async (page) => {
		setLoading(true);

		try {
			const data = await getMostViewedVideos(page, currLang, limit);
			setMostViewedVideos(data?.data?.data);
			setTotalMostViewedCount(data?.data?.pageLimit);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};
	const fetchAllVideos = async (page) => {
		setLoading(true);
		try {
			const data = await getAllVideos(page, currLang, limit);
			setAllVideos(data?.data?.data);
			setTotalAllVideoCount(data?.data?.pageLimit);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};
	const fetchLikedVideos = async (page) => {
		setLoading(true);
		try {
			const res = await getLikedVideos(page, currLang, userId, limit);
			const formatedData = res.data.result.map((item) => item.video);
			setLikedVideos(formatedData);
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const fetchContinueVideos = async (page) => {
		setLoading(true);
		try {
			const res = await getContinueWatching(page, currLang, limit);
			const formatedData = res.data.result.map((item) => {
				return { ...item.video, lastWatched: item.lastWatched };
			});
			setContinueVideos(formatedData);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchFeaturedVideos(0);
		fetchMostViewedVideos(0);
		fetchAllVideos(0);
		if (auth) {
			fetchLikedVideos(0);
			fetchContinueVideos(0);
		}
	}, [currLang, limit]);

	return (
		<>
			<section className='home-container ps-3 '>
				<div className='row justify-content-center'>
					<Notification />
				</div>

				<main className='home-wrapper scroller my-3'>
					{/* Continue watching  */}

					{auth &&
					(currDisplay === HOME_VIDEOS || currDisplay === CONTINUE_WATCHING) ? (
						<div>
							<div className='w-100 d-flex justify-content-between headingStrip-wrapper py-2 pe-0 pe-lg-5'>
								<h4>{t('Continue Watching')} </h4>
								{currDisplay === HOME_VIDEOS ? (
									<h5
										className='pointer'
										onClick={() => setCurrDisplay(CONTINUE_WATCHING)}
									>
										{t('View all')}
										<KeyboardArrowRightIcon />
									</h5>
								) : (
									<div className='d-flex gap-2'>
										<Button onClick={() => setCurrDisplay(HOME_VIDEOS)}>
											{t('Back')}
										</Button>
										<Pagination
											current={continueVideoPage + 1}
											total={totalContinueVideoCount}
											defaultPageSize={10}
											showSizeChanger={true}
											onChange={(clickedPage, pageSize) => {
												fetchContinueVideos(clickedPage - 1);
												setContinueVideoPage(clickedPage - 1);
												setLimit(pageSize);
											}}
										/>
									</div>
								)}
							</div>
							<div className='d-flex allcard-wrapper '>
								{continueVideos?.length > 0
									? continueVideos?.map((item, index) => {
											if (currDisplay === HOME_VIDEOS && index >= 4)
												return null;
											return (
												<>
													<Card
														index={index}
														key={index}
														id={item._id}
														urltitle={item.urltitle}
														duration={item.duration}
														date={item.date}
														uploadedBy={item.uploadedBy}
														url={
															item[currLangBackend]?.url ||
															item?.englishInfo?.url
														}
														thumbnail={
															item[currLangBackend]?.thumbnail ||
															item?.englishInfo?.thumbnail
														}
														title={
															item[currLangBackend]?.title ||
															item?.englishInfo?.title
														}
														description={
															item[currLangBackend]?.description ||
															item?.englishInfo?.description
														}
													/>
													)
												</>
											);
									  })
									: loading && <Skeleton />}
							</div>
						</div>
					) : null}

					{/* featured video */}

					{currDisplay === HOME_VIDEOS || currDisplay === FEATURED_VIDEOS ? (
						<div>
							<div className='w-100 d-flex justify-content-between headingStrip-wrapper py-2 pe-0 pe-lg-5'>
								<h4>{t('Featured videos')} </h4>
								{currDisplay === HOME_VIDEOS ? (
									<h5
										className='pointer'
										onClick={() => setCurrDisplay(FEATURED_VIDEOS)}
									>
										{t('View all')}
										<KeyboardArrowRightIcon />
									</h5>
								) : (
									<div className='d-flex gap-2'>
										<Button onClick={() => setCurrDisplay(HOME_VIDEOS)}>
											{t('Back')}
										</Button>
										<Pagination
											current={featuredPage + 1}
											total={totalFeaturedCount}
											defaultPageSize={10}
											showSizeChanger={true}
											onChange={(clickedPage, pageSize) => {
												fetchFeaturedVideos(clickedPage - 1);
												setFeaturedPage(clickedPage - 1);
												setLimit(pageSize);
											}}
										/>
									</div>
								)}
							</div>
							<div className='d-flex allcard-wrapper '>
								{featuredVideos?.length > 0
									? featuredVideos?.map((item, index) => {
											if (currDisplay === HOME_VIDEOS && index >= 4)
												return null;
											return (
												<Card
													index={index}
													key={index}
													id={item._id}
													duration={item.duration}
													date={item.date}
													urltitle={item.urltitle}
													uploadedBy={item.uploadedBy}
													url={
														item[currLangBackend]?.url
															? item[currLangBackend]?.url
															: item?.englishInfo?.url
													}
													thumbnail={
														item[currLangBackend]?.thumbnail
															? item[currLangBackend]?.thumbnail
															: item?.englishInfo?.thumbnail
													}
													title={
														item[currLangBackend]?.title
															? item[currLangBackend]?.title
															: item?.englishInfo?.title
													}
													description={
														item[currLangBackend]?.description ||
														item?.englishInfo?.description
													}
												/>
											);
									  })
									: loading && <Skeleton />}
							</div>
						</div>
					) : null}

					{/* Most viewed video */}

					{currDisplay === HOME_VIDEOS || currDisplay === MOST_VIEWED_VIDEOS ? (
						<div>
							<div className='w-100 d-flex justify-content-between headingStrip-wrapper py-2 pe-0 pe-lg-5'>
								<h4>{t('Most viewed videos')}</h4>
								{currDisplay === HOME_VIDEOS ? (
									<h5
										className='pointer'
										onClick={() => setCurrDisplay(MOST_VIEWED_VIDEOS)}
									>
										{t('View all')} <KeyboardArrowRightIcon />
									</h5>
								) : (
									<div className='d-flex gap-2'>
										<Button onClick={() => setCurrDisplay(HOME_VIDEOS)}>
											{t('Back')}
										</Button>
										<Pagination
											current={mostViewedPage + 1}
											total={totalMostViewedCount}
											defaultPageSize={10}
											showSizeChanger={true}
											onChange={(clickedPage, pageSize) => {
												fetchMostViewedVideos(clickedPage - 1);
												setMostViewedPage(clickedPage - 1);
												setLimit(pageSize);
											}}
										/>
									</div>
								)}
							</div>
							<div className='d-flex allcard-wrapper '>
								{mostViewedVideos.length > 0
									? mostViewedVideos?.map((item, index) => {
											if (currDisplay === HOME_VIDEOS && index >= 4)
												return null;
											return (
												<Card
													index={index}
													key={index}
													id={item._id}
													duration={item.duration}
													date={item.date}
													urltitle={item.urltitle}
													uploadedBy={item.uploadedBy}
													url={
														item[currLangBackend]?.url || item?.englishInfo?.url
													}
													thumbnail={
														item[currLangBackend]?.thumbnail ||
														item?.englishInfo?.thumbnail
													}
													title={
														item[currLangBackend]?.title ||
														item?.englishInfo?.title
													}
													description={
														item[currLangBackend]?.description ||
														item?.englishInfo?.description
													}
												/>
											);
									  })
									: loading && <Skeleton />}
							</div>
						</div>
					) : null}

					{/* Liked videos
          {auth &&
          (currDisplay === HOME_VIDEOS || currDisplay === LIKED_VIDEOS) ? (
            <div>
              <div className="w-100 d-flex justify-content-between headingStrip-wrapper py-2 pe-1 pe-lg-5">
                <h4>{t("Liked Videos")} </h4>
                {currDisplay === HOME_VIDEOS ? (
                  <h5
                    className="pointer"
                    onClick={() => setCurrDisplay(LIKED_VIDEOS)}
                  >
                    {t("View all")} <KeyboardArrowRightIcon />
                  </h5>
                ) : (
                  <div className="d-flex gap-2">
                    <Button onClick={() => setCurrDisplay(HOME_VIDEOS)}>
                      {t("Back")}
                    </Button>
                    <Pagination
                      current={likedVideoPage + 1}
                      total={totalLikedVideoCount}
                      defaultPageSize={10}
                      onChange={(clickedPage) => {
                        fetchLikedVideos(clickedPage - 1);
                        setlikedVideoPage(clickedPage - 1);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="d-flex allcard-wrapper ">
                {likedVideos?.map((item, index) => {
                  if (currDisplay === HOME_VIDEOS && index >= 4) return null;
                  return (
                    <>
                      {loading ? (
                        <Skeleton />
                      ) : (
                        <Card
                          index={index}
                          key={index}
                          id={item._id}
                          duration={item.duration}
                          date={item.date}
                          uploadedBy={item.uploadedBy}
                          url={
                            item[currLangBackend]?.url || item?.englishInfo?.url
                          }
                          thumbnail={
                            item[currLangBackend]?.thumbnail ||
                            item?.englishInfo?.thumbnail
                          }
                          title={
                            item[currLangBackend]?.title ||
                            item?.englishInfo?.title
                          }
                          description={
                            item[currLangBackend]?.description ||
                            item?.englishInfo?.description
                          }
                        />
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          ) : null} */}

					{/* All videos */}

					{currDisplay === HOME_VIDEOS || currDisplay === ALL_VIDEOS ? (
						<div>
							<div className='w-100 d-flex justify-content-between headingStrip-wrapper py-2 pe-1 pe-lg-5'>
								<h4>{t('All videos')} </h4>
								{currDisplay === HOME_VIDEOS ? (
									<h5
										className='pointer'
										onClick={() => setCurrDisplay(ALL_VIDEOS)}
									>
										{t('View all')} <KeyboardArrowRightIcon />
									</h5>
								) : (
									<div className='d-flex gap-2'>
										<Button onClick={() => setCurrDisplay(HOME_VIDEOS)}>
											{t('Back')}
										</Button>
										<Pagination
											current={allVideoPage + 1}
											total={totalAllVideoCount}
											defaultPageSize={10}
											showSizeChanger={true}
											onChange={(clickedPage, pageSize) => {
												fetchAllVideos(clickedPage - 1);
												setAllVideoPage(clickedPage - 1);
												setLimit(pageSize);
											}}
										/>
									</div>
								)}
							</div>
							<div className='d-flex allcard-wrapper '>
								{allVideos?.map((item, index) => {
									if (currDisplay === HOME_VIDEOS && index >= 4) return null;
									return (
										<Card
											index={index}
											key={index}
											id={item._id}
											urltitle={item.urltitle}
											duration={item.duration}
											date={item.date}
											uploadedBy={item.uploadedBy}
											url={item[currLangBackend]?.url || item?.englishInfo?.url}
											thumbnail={
												item[currLangBackend]?.thumbnail ||
												item?.englishInfo?.thumbnail
											}
											title={
												item[currLangBackend]?.title || item?.englishInfo?.title
											}
											description={
												item[currLangBackend]?.description ||
												item?.englishInfo?.description
											}
										/>
									);
								})}
							</div>
						</div>
					) : null}
				</main>
			</section>
		</>
	);
}

export default Home;
