/** @format */

import NotificationsIcon from '@mui/icons-material/Notifications';
import { IconButton } from '@mui/material';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Popper from '@mui/material/Popper';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import '../../Assets/Css/header.css';
import logo from '../../Assets/Images/newlogo.png';
import person from '../../Assets/Images/men.png';
import { getAllNotificationPanel, GetUser } from '../Api/Api';
import useAuth from '../hooks/useAuth';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import NotificationCard from './NotificationCard';
import SearchIcon from '@mui/icons-material/Search';
import userAvatar from '../../Assets/Images/user.jpg';
import useLang from '../hooks/useLang';
function Header({ langChange }) {
	const { auth, user } = useAuth();
	const [isLoggedIn, setisLoggedIn] = useState(false);
	const [loginData, setloginData] = useState();
	const navigate = useNavigate();
	const { t, i18n } = useTranslation(['main']);
	// notification
	const [open, setOpen] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [placement, setPlacement] = React.useState('top-right');
	const [query, setQuery] = useState('');
	const [ResponsiveSearch, setResponsiveSearch] = useState(false);
	const [notificationData, setnotificationData] = useState([]);
	const LangRef = useRef(null);
	const searchRef = useRef(null);

	const handleClick = (newPlacement) => (event) => {
		setAnchorEl(event.currentTarget);
		setOpen((prev) => placement !== newPlacement || !prev);
		setPlacement(newPlacement);
		setOpen3(false);
		setResponsiveSearch(false);
	};

	const getNotificationPanel = async () => {
		try {
			const res = await getAllNotificationPanel();

			setnotificationData(res.data.notification);
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		getNotificationPanel();
	}, []);

	useEffect(() => {
		if (auth) {
			setisLoggedIn(true);
			const calldata = async () => {
				try {
					const data = await GetUser();
					setloginData(data?.data?.data);
				} catch (error) {
					console.log(error);
				}
			};
			calldata();
		} else {
			setisLoggedIn(false);
		}
	}, []);

	useEffect(() => {
		if (localStorage.getItem('i18nextLng')?.length > 2) {
			i18n.changeLanguage('en');
		}
	}, []);

	const handleLanguage = (e) => {
		i18n.changeLanguage(e.target.value);
	};

	//popper
	const [anchorEl2, setanchorEl2] = React.useState(null);
	const open2 = Boolean(anchorEl2);
	const handleClick2 = (event) => {
		setanchorEl2(event.currentTarget);
		setOpen(false);
	};
	const handleClose = () => {
		setanchorEl2(null);
	};

	const handleLogout = () => {
		handleClose();
		localStorage.removeItem('token');
		localStorage.removeItem('accessToken');
		setisLoggedIn(false);
		window.location.reload();
	};
	const handleSearch = (e) => {
		if (!query) return;
		if (e.key === 'Enter') {
			e.preventDefault();
			navigate(`/search/${query}`);
			e.target.value = '';
		}
	};

	const openSearch = () => {
		if (!ResponsiveSearch) {
			setResponsiveSearch(true);
			setOpen(false);
			setOpen3(false);
		} else {
			setOpen3(false);
			setOpen(false);
			setResponsiveSearch(false);
		}
	};

	// transation popper
	const [anchorEl3, setAnchorEl3] = React.useState(null);
	const [open3, setOpen3] = React.useState(false);

	const handleClick3 = (event) => {
		// open3 = Boolean(anchorEl3)
		setOpen3((previousOpen) => !previousOpen);
		setOpen(false);
		setResponsiveSearch(false);
		setAnchorEl3(event.currentTarget);
	};
	const { currLangBackend } = useLang();

	const handleClickOutside = (e) => {
		if (LangRef.current && !LangRef.current.contains(e.target)) {
			setOpen3(false);
		}
		if (searchRef.current && !searchRef.current.contains(e.target)) {
			setResponsiveSearch(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<>
			<header className='header-wrapper'>
				<div className='header-notification'></div>
				<nav className='header-contet  py-2'>
					<div className='container-fluid d-flex'>
						<div className='col-6 col-lg-8 d-flex logo-container'>
							<img
								src={logo}
								alt='logo'
								onClick={() => navigate('/')}
								className='logo-main'
							/>
							<form
								className='flex search-container'
								role='search'
								style={{ width: '30%' }}
							>
								<input
									className='me-2 header-search-box'
									type='search'
									placeholder={`${t('Search Videos...')}`}
									aria-label='Search'
									onChange={(e) => setQuery(e.target.value)}
									onKeyDown={handleSearch}
								/>
							</form>
						</div>

						<div className='col-lg-4 col-6 login-container'>
							<div className='my-2' ref={searchRef}>
								<SearchIcon
									className={`search-icon-resp  ${
										ResponsiveSearch ? 'active-icon' : null
									}`}
									onClick={openSearch}
								/>
							</div>

							<div className='dropdown mx-3' ref={LangRef}>
								<GTranslateIcon
									onClick={handleClick3}
									className={`translation-icon my-2 ${
										open3 ? 'active-icon' : null
									}`}
								/>
								<select
									className='w-100 lang-select'
									onChange={handleLanguage}
									aria-label='.form-select-lg example'
									value={localStorage.getItem('i18nextLng')}
								>
									<option value='en'>English</option>
									<option value='hi'>हिन्दी</option>
									<option value='mr'>मराठी</option>
									<option value='te'>తెలుగు</option>
									<option value='ta'>தமிழ்</option>
									<option value='kr'>ಕನ್ನಡ</option>
								</select>
							</div>

							{isLoggedIn ? (
								<div className='d-flex align-items-center afterlogin'>
									{/* <NotificationsIcon
                    onClick={handleClick("bottom-end")}
                    className={`${open ? "active-icon" : null}`}
                  /> */}
									<h5 style={{ fontSize: '15px', color: '#455e2b' }}>
										{t('Hi')} {loginData?.name}!
									</h5>
									<IconButton
										id='basic-button'
										aria-controls={open2 ? 'basic-menu' : undefined}
										aria-haspopup='true'
										aria-expanded={open2 ? 'true' : undefined}
										onClick={handleClick2}
									>
										<img
											src={loginData?.profile || userAvatar}
											alt='noimg'
											className=''
										/>
									</IconButton>
									<Menu
										id='basic-menu'
										anchorEl={anchorEl2}
										open={open2}
										onClose={handleClose}
										MenuListProps={{
											'aria-labelledby': 'basic-button',
										}}
									>
										<MenuItem onClick={() => navigate('/editprofile')}>
											{t('Profile')}
										</MenuItem>
										<MenuItem onClick={() => navigate('/changepassword')}>
											{t('Change password')}
										</MenuItem>
										<MenuItem onClick={handleLogout}> {t('Logout')} </MenuItem>
									</Menu>
								</div>
							) : (
								<>
									<button
										onClick={() => navigate('/login')}
										className='btn-login'
									>
										{t('signin')}
									</button>
								</>
							)}
						</div>
					</div>
				</nav>
			</header>
			{/* responsive search bar */}
			{ResponsiveSearch ? (
				<div className='resp-search-bar'>
					<input
						className='header-search-box me-2 '
						type='search'
						placeholder={`${t('Search all videos')}`}
						aria-label='Search'
						onChange={(e) => setQuery(e.target.value)}
						onKeyDown={handleSearch}
					/>
				</div>
			) : null}

			<Popper
				open={open}
				anchorEl={anchorEl}
				transition
				style={{ zIndex: '10000', marginTop: '15px' }}
			>
				{({ TransitionProps }) => (
					<Fade {...TransitionProps} timeout={350}>
						<div className='popper-Box'>
							<div className='d-flex flex-column py-2'>
								{notificationData
									?.filter((item) => item.active === true)
									.map((item, index) => (
										<NotificationCard
											image={item.image}
											createdAt={item.createdAt}
											title={item[currLangBackend]}
										/>
									))}
							</div>
						</div>
					</Fade>
				)}
			</Popper>

			<Popper
				open={open3}
				placement={'bottom-end'}
				anchorEl={anchorEl3}
				transition
				style={{ zIndex: '10000', marginTop: '15px' }}
			>
				{({ TransitionProps }) => (
					<Fade {...TransitionProps} timeout={350}>
						<div className='popper-Box py-2'>
							<option value='en' className='pb-2' onClick={handleLanguage}>
								English
							</option>
							<option value='hi' className='pb-2' onClick={handleLanguage}>
								हिन्दी
							</option>
							<option className='pb-2' value='mr' onClick={handleLanguage}>
								मराठी
							</option>
							<option className='pb-2' value='te' onClick={handleLanguage}>
								తెలుగు
							</option>
							<option className='pb-2' value='ta' onClick={handleLanguage}>
								தமிழ்
							</option>
							<option className='pb-2' value='kr' onClick={handleLanguage}>
								ಕನ್ನಡ
							</option>
						</div>
					</Fade>
				)}
			</Popper>
		</>
	);
}

export default Header;
