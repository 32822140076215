import React from "react";
import useLang from "../hooks/useLang";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getAllNotification } from "../Api/Api";
import { useEffect } from "react";
import Marquee from "react-fast-marquee";

export const Notification = ({ className }) => {
  const { currLang, currLangBackend } = useLang();
  const { t } = useTranslation(["main"]);
  const [notification, setNotification] = useState("");

  const fetchNotification = async () => {
    try {
      const res = await getAllNotification();
      setNotification(res.data.notification);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchNotification();
  }, []);
  return (
    <div
      className="col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-8 mobile-notification"
      style={{ margin: className === "notification" ? "0 auto" : "" }}
    >
      {notification && (
        <Marquee
          gradient={false}
          gradientWidth={0}
          style={{
            background: "#E6F2F9",
            color: "#0645AD",
            height: "40px",
            fontSize: "13px",
            marginTop: "5px",
            fontWeight: 500,
            cursor: "pointer",
            borderRadius: "4px",
            textDecoration: "underline",
          }}
        >
          <p className="mx-3">
            <a href={`https://${notification.link}`} target="_blank">
              {notification[currLangBackend] ||
                "This is some notification text which is supposed to flow etc  and the underline suggests clickable link"}
              <span>&#8226;</span>
            </a>
          </p>
        </Marquee>
      )}
    </div>
  );
};
