/** @format */

import ShareIcon from '@mui/icons-material/Share';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Button from '@mui/material/Button';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import '../../../Assets/Css/playlistsinglevideo.css';
import channelLogo from '../../../Assets/Images/logo.svg';
import { Helmet } from 'react-helmet';
import {
	getPlaylist,
	getPlaylistByTitle,
	getVideoByTitle,
	GetVideosById,
	likeVideo,
	postDurationStats,
} from '../../Api/Api';
import 'videojs-plus/dist/plugins/unload';
import RelatedCards from './RelatedCards';
import toast from 'react-hot-toast';
import Player from '../../Common/Player';
import useLang from '../../hooks/useLang';
import { percent } from '../../Helper/helperFunctions';
import useAuth from '../../hooks/useAuth';

const playerOptions = {};

function PlaylistSingleVideo() {
	const { t } = useTranslation(['main']);
	const param = useParams();
	const { auth } = useAuth();
	let playlistTitle = param?.id1;
	let videoTitle = param?.id2;
	const [playlistId, setPlaylistId] = useState('');
	const [videoId, setVideoId] = useState('');
	const [videoData, setVideoData] = useState([]);
	const [player, setPlayer] = useState(null);
	const [video, setVideo] = useState(null);
	const [copy, setCopy] = useState(false);
	const [isLike, setIsLike] = useState(videoData?.liked);
	const [likeCount, setLikeCount] = useState(videoData?.videoStatId?.likes);
	const [videoList, setVideoList] = useState([]);
	let intervalRef = useRef(null);
	const { currLang, currLangBackend } = useLang();
	const navigate = useNavigate();

	const fetchIds = async () => {
		try {
			const data = await getPlaylistByTitle(playlistTitle);
			setPlaylistId(data?.data?.result?._id);

			const res = await getVideoByTitle(videoTitle);
			setVideoId(res?.data?.result?._id);
		} catch (error) {
			console.log(error);
			toast.error(error?.response?.data?.message);
		}
	};

	const getData = async () => {
		try {
			const data = await GetVideosById(videoId);
			setVideoData(data?.data?.data);
		} catch (error) {
			console.log(error);
			toast.error(error?.response?.data?.message);
		}
	};

	useEffect(() => {
		setVideo({
			sources: [
				{
					src: videoData[currLangBackend]?.url,
					type: 'video/mp4',
				},
			],
		});
	}, [videoData, currLangBackend]);

	const handleStats = async (time) => {
		// console.log(time, videoData);
		try {
			const data = await postDurationStats({
				videoId: videoData?._id,
				lastWatched: time,
				completelyWatched:
					videoData?.progress?.completelyWatched ||
					percent(time, videoData?.duration) > 80,
			});
		} catch (error) {
			console.log(error);
			toast.error(error?.response?.data?.message);
		}
	};

	const fetchVideos = async (page) => {
		try {
			const res = await getPlaylist(playlistId, page);
			// videoListRef.current = res.data.data;
			setVideoList(res.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		setIsLike(videoData?.liked);
		setLikeCount(videoData?.videoStatId?.likes);
	}, [videoData]);

	useEffect(() => {
		if (playlistId !== '') {
			fetchVideos(0);
		}
	}, [playlistId]);

	useEffect(() => {
		fetchIds();
	}, []);

	useEffect(() => {
		if (player && videoId !== '') {
			player.unload({ loading: true });
			getData();
		}
	}, [player, videoId]);

	useEffect(() => {
		if (player && video) {
			player.src(video.sources);
		}
	}, [video, player]);

	function onPlayerEnded() {
		let nextVideoIndex = -1;
		let currVideoIndex = videoList.findIndex((item) => item._id == videoId);
		if (currVideoIndex === videoList.length - 1) {
			nextVideoIndex = 0;
		} else {
			nextVideoIndex = currVideoIndex + 1;
		}
		clearInterval(intervalRef.current);
		intervalRef.current = null;
		navigate(`/playlist/${playlistId}/video/${videoList[nextVideoIndex]?._id}`);
	}

	useEffect(() => {
		if (player) {
			player.on('ended', onPlayerEnded);

			player.on('loadedmetadata', function () {
				player.currentTime(videoData?.progress?.lastWatched);
			});

			return () => {
				player.off('ended', onPlayerEnded);
			};
		}
	}, [player, videoList, videoId, videoData]);

	useEffect(() => {
		if (player && videoData.duration && !intervalRef.current) {
			intervalRef.current = setInterval(() => {
				handleStats(player.cache_.currentTime);
			}, [6000]);
		}
		return () => {
			clearInterval(intervalRef.current);
		};
	}, [player, videoData]);

	const handleLike = async () => {
		if (!auth) {
			navigate('/login');
		}
		try {
			const res = await likeVideo({ videoId: videoId, action: '0' });
			if (res.data.message === 'like') {
				setLikeCount((prev) => prev + 1);
				setIsLike(true);
			}
			if (res.data.message === 'dislike') {
				setLikeCount((prev) => prev - 1);
				setIsLike(false);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const copyLink = () => {
		setCopy(true);
		navigator.clipboard.writeText(window.location.href);
		setTimeout(() => setCopy(false), 1500);
	};

	return (
		<>
			<Helmet>
				<title>{videoData?.englishInfo?.title}</title>
				<meta property='og:title' content={videoData?.englishInfo?.title} />
				<meta
					property='og:description'
					content={videoData?.englishInfo?.description}
				/>
				<meta property='og:image' content={videoData?.englishInfo?.thumbnail} />
				{/* <meta property='og:video' content={videoData?.englishInfo?.url} />
				<meta property='og:video:type' content='video/mp4' />
				<meta property='og:video:width' content='640' />
				<meta property='og:video:height' content='480' /> */}

				<meta name='twitter:card' content='summary_large_image' />
				<meta name='twitter:title' content={videoData?.englishInfo?.title} />
				<meta
					name='twitter:description'
					content={videoData?.englishInfo?.description}
				/>
				<meta
					name='twitter:image'
					content={videoData?.englishInfo?.thumbnail}
				/>
			</Helmet>
			<section className='flex-column container-fluid px-0  '>
				<div className='video-container' style={{ Height: '100px' }}>
					<Player
						playerOptions={playerOptions}
						onPlayerInit={setPlayer}
						onPlayerDispose={setPlayer}
					/>
				</div>
				<main className='video-detail-wrapper container-xxl py-5 d-flex flex-column flex-lg-row m-1'>
					<div className='col-12 col-lg-8 px-lg-5'>
						<div className='flex-column'>
							<div className='custom-card'>
								<h3
									className='playlistTitle'
									onClick={() => navigate(`/playlist/${playlistId}`)}
								>
									{videoData?.playlist?.[currLangBackend]} :&nbsp;
								</h3>
								<h4 className='videoTitle'>
									{videoData[currLangBackend]?.title}
								</h4>
							</div>
							<div className='d-flex justify-content-end my-1 video-detail-head flex-end'>
								<div className='d-flex'>
									<Button
										className='me-5 align-items-center'
										onClick={handleLike}
									>
										{isLike ? <ThumbUpIcon /> : <ThumbUpOffAltIcon />}{' '}
										{likeCount}
									</Button>
									<Button
										className={`${copy && 'copied'}`}
										onClick={() => copyLink()}
										size='small text-dark  align-self-flex-end'
									>
										<ShareIcon />
									</Button>
									{/* <span className='align-items-center'><ThumbDownOffAltIcon /> Dislike</span> */}
								</div>
							</div>
							<p>
								{videoData?.videoStatId?.views} views | {videoData?.date}
							</p>
						</div>

						<hr />

						<div className='video-description col-12 col-lg-3'>
							<figure className='d-flex align-items-center'>
								<img src={channelLogo} alt='Noimg' />
								<figcaption> {videoData?.uploadedBy}</figcaption>
							</figure>
							<p>
								{videoData[currLangBackend]?.description ||
									videoData?.englishInfo?.description}
							</p>
						</div>
					</div>
					<aside className='playlist-sideplaylist px-2 py-lg-0 py-4'>
						<h5 className='mb-4'>Next in Playlist</h5>
						<div>
							{videoList.map((item) => (
								<RelatedCards
									videoId={videoId}
									playlistId={playlistId}
									key={item._id}
									item={item}
								/>
							))}
						</div>
					</aside>
				</main>
			</section>
		</>
	);
}

export default PlaylistSingleVideo;
