import React from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import "../../Assets/Css/header.css";
import person from "../../Assets/Images/men.png";
import { Link } from "react-router-dom";
function NotificationCard({ image, title, createdAt }) {
  return (
    <>
      <div className="container notification-card py-3">
        <div className="d-flex align-items-center notification-content">
          <img src={image || person} alt="Noimg" />
          <h5 className="ps-4">
            {title ||
              "Dennis Matthew uploaded a new playlist: Taking care of the crops"}
          </h5>
        </div>
        <span className="ps-5 d-flex justify-content-between ">
          <p>{new Date(createdAt).toDateString()}</p>{" "}
        </span>
      </div>
    </>
  );
}

export default NotificationCard;
