/** @format */

import React, { useState } from 'react';
import '../../../Assets/Css/signup.css';
import logo from '../../../Assets/Images/newlogo.png';
import Googlelogo from '../../../Assets/Images/googleicon.png';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StateJSON } from '../../../utils/stateJson';

function Signup2(props) {
	const navigate = useNavigate();
	const { t } = useTranslation(['main']);
	const [state, setState] = useState('');

	return (
		<>
			<form className='signupForm-wrapper p-3' style={{ minWidth: 350 }}>
				<IconButton onClick={props.handlepage}>
					<ArrowBackIcon />
				</IconButton>
				<div className='d-flex justify-content-center pb-2'>
					<img src={logo} alt='No-Img' className='logo-img' />
				</div>

				<p className='text-center py-2'>{t('Step')} 2 of 3</p>

				<h4 className='text-center py-2'>{t('Create your account')}</h4>

				<div className='form-outline mb-4'>
					<label className='form-label' for='form2Example1'>
						{t('Designation')} <span style={{ color: 'Red' }}> &nbsp;*</span>
					</label>
					<input
						type='text'
						id='form2Example1'
						name='designation'
						placeholder={t('Designation')}
						onChange={props.fieldChange}
						className='form-control'
					/>
				</div>

				<div className='form-outline mb-4'>
					<label className='form-label' for='form2Example1'>
						{t('Age')} <span style={{ color: 'Red' }}> &nbsp;*</span>
					</label>
					<input
						type='number'
						id='form2Example1'
						value={props.data?.age}
						min={0}
						max={120}
						placeholder={t('Age')}
						name='age'
						onChange={props.fieldChange}
						className='form-control'
					/>
				</div>

				<div className='form-outline mb-4'>
					<label className='form-label' for='form2Example1'>
						{t('State')} <span style={{ color: 'Red' }}> &nbsp;*</span>{' '}
					</label>
					<select
						className='form-control'
						name='state'
						value={props.data?.state}
						onChange={props.fieldChange}
					>
						<option value=''>{t('Please select State')}</option>
						{Object.keys(StateJSON).map((key) => (
							<option value={key} key={key}>
								{key}
							</option>
						))}
					</select>
				</div>
				<div className='form-outline mb-4'>
					<label className='form-label' for='form2Example1'>
						{t('City')} <span style={{ color: 'Red' }}> &nbsp;*</span>{' '}
					</label>
					<select
						className='form-control'
						onChange={props.fieldChange}
						value={props.data?.city}
						name='city'
					>
						<option value=''>{t('Please Select City')}</option>
						{StateJSON[props?.data?.state]?.map((key) => (
							<option value={key} key={key}>
								{key}
							</option>
						))}
					</select>
				</div>
				<div className='form-outline mb-4'>
					<label className='form-label' for='form2Example1'>
						{t('Address')}
					</label>
					<textarea
						class='form-control'
						name='address'
						placeholder={t('Address')}
						value={props.data?.address}
						onChange={props.fieldChange}
						id='exampleFormControlTextarea1'
						rows='1'
					></textarea>
				</div>

				<button
					type='button'
					onClick={() => props.setpage(3)}
					disabled={
						!props.data?.age ||
						!props.data?.designation ||
						!props.data?.city ||
						!props.data?.state
							? true
							: false
					}
					className='btn text-white  w-100 py-2 mb-4'
					style={{ backgroundColor: 'rgba(4, 195, 92, 1)' }}
				>
					{' '}
					{t('Next')}{' '}
				</button>

				<div className='text-center'>
					<p>
						{' '}
						{t('Have an account?')} &nbsp;
						<Link to={'/'}>{t('Signin')}</Link>
					</p>
				</div>
			</form>
		</>
	);
}

export default Signup2;
